$main-txt: #242424;
$white-txt: #FFFFFF;
$main-btn: #BA564C;
/* Стандартный transition для hover-анимаций */

@mixin transition {
  transition: 0.3s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}


/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
@mixin placeholder($color, $opacity: 1) {
  &::-webkit-input-placeholder {color:$color; opacity: $opacity}
  &::-moz-placeholder          {color:$color; opacity: $opacity}
  &:-ms-input-placeholder      {color:$color; opacity: $opacity}
}

//flex-миксин
@mixin flex-dir($dir: column, $jc:center, $ai:center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jc;
  align-items: $ai;
}

//Перевод пикселей в емы
@function rem($pixels, $context: 16) {
  @return $pixels / $context * 1rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: $main-txt;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p{
  font-weight: normal;
  margin: 0;
  padding: 0;
}
ul,li {
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 8px;
  @media (max-width: 768px) {
    max-width: 480px;
    padding: 0 16px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

.fade-animation {
  -webkit-animation: fade-animation;
  animation: fade-animation 2s linear;
  //will-change: animation;
}

@-webkit-keyframes fade-animation {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.logo {
  @media (max-width: 768px) {
    width: 128px;
  }
}

.login-btn {
  font-size: rem(12);
  line-height: 130%;
  color: $main-txt;
  padding: 12px 12px 11px;
  background: transparent;
  border: 1px solid $main-txt;
  border-radius: 4px;
  width: 100%;
  max-width: 87px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-in-out;
  &:hover {
    color: $white-txt;
    background: $main-btn;
  }
  @media (max-width: 768px) {
    font-size: rem(10);
    max-width: 79px;
    padding: 7px;
  }
}

.slick-dots {
  bottom: -30px;
}
.slide-arrow{
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #F5DDD1;
  border: none;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:before, &:after {
    content: ' ';
    display: block;
    width: 11px;
    height: 2px;
    background: #C19783;
    border-radius: 8px;
    position: absolute;
  }
}
.prev-arrow{
  left: -70px;
  &:before {
    transform: rotate(45deg);
    top: 32px;
    left: 24px;
  }
  &:after {
    transform: rotate(-45deg);
    top: 25px;
    left: 24px;
  }
}
.next-arrow{
  right: -70px;
  &:before {
    transform: rotate(45deg);
    top: 25px;
    left: 25px;
  }
  &:after {
    transform: rotate(-45deg);
    top: 32px;
    left: 25px;
  }
}

.slick-dots li {
  width: 10px;
  height: 10px;
  &.slick-active {
    & button {
      opacity: 1;
    }
  }
  & button {
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background: #C19783;
    opacity: 0.3;
    &:before {
      display: none;
    }
  }
}
.censor {
  display: none;
}
@import './src/blocks/main/main';
@import './src/blocks/footer/footer';
@import './src/blocks/form/form';
@import './src/blocks/button/button';
@import './src/blocks/menu/menu';
@import './src/blocks/ad/ad';
@import './src/blocks/foryou/foryou';
@import './src/blocks/marathon/marathon';
@import './src/blocks/habits/habits';
@import './src/blocks/after/after';
@import './src/blocks/order/order';
@import './src/blocks/timer/timer';
@import './src/blocks/results/results';
@import './src/blocks/faq/faq';
