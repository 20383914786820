.main {
    background: #FDF7F4 calc(50% + 365px) 10px url('../img/main-bg.png') no-repeat;
    padding: 120px 0 214px;
    color: $main-txt;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        padding: 40px 0 280px;
        background-size: 228px;
        background-position: center bottom;
    }
    &__title {
        font-size: rem(65);
        line-height: 120%;
        font-family: 'Cormorant';
        color: $main-txt;
        width: 100%;
        max-width: 700px;
        text-transform: uppercase;
        @media (max-width: 1000px) {
            font-size: rem(60);
        }
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__subtitle {
        font-size: rem(20);
        line-height: 130%;
        font-weight: 500;
        width: 100%;
        max-width: 700px;
        margin-top: 30px;
        & span {
            color: $main-btn;
        }
        @media (max-width: 768px) {
            font-size: rem(14);
            margin-top: 10px;
        }
    }
    & .button {
        margin-top: 50px;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
    & .ad {
        margin-top: 30px;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
}
