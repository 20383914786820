.footer {
  color: #595959;
  background-color: transparent;
  padding: 40px 0 20px;

  &__inner {
    display: flex;
    justify-content: space-around;
    align-items: end;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: fit-content;
  }

  &__info {
    margin-bottom: 8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 113%;

    &--link {
      text-decoration: none;
      color: #595959;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--legal {
      margin-bottom: -4px;
      line-height: 170%;
    }

    &--title {
      margin: 0 0 3px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }
  }

  &__pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 280px;
    margin: 0 0 16px;
    gap: 10px;

    &--cards {
      align-items: baseline;
      max-width: 185px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 600px) {
  .footer {

    &__inner {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      max-width: 270px;

      .footer__info--title {
        text-align: left;
      }

      &:nth-child(2) {
        order: 3;

        .footer__pay--cards {
          margin-left: 0;
        }
      }
    }
  }
}
