.faq {
    background: #F5DDD1 calc(50% + 470px) top url('../img/order-bg.png') no-repeat;
    padding: 120px 0;
    color: $main-txt;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        background: #F5DDD1 right top url('../img/order-mobile-bg.png') no-repeat;
        padding: 60px 0;
    }
    &__title {
        font-size: rem(65);
        line-height: 120%;
        font-family: 'Cormorant';
        color: $main-txt;
        width: 100%;
        max-width: 700px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__list {
        width: 100%;
        margin: 50px 0 0;
        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }
    &__item {
        background: $white-txt;
        border: 1px dashed #C19783;
        border-radius: 4px;
        padding: 29px 40px;
        margin-bottom: 10px;
        cursor: pointer;
        @media (max-width: 768px) {
            padding: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.active .faq__question {
            &:before, &:after {
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
    &__question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &:after {
            content: ' ';
            display: block;
            width: 2px;
            height: 20px;
            background: #BA564C;
            border-radius: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
            text-align: center;
            color: #BA564C;
            transition-duration: 0.3s;
            @media (max-width: 768px) {
                height: 16px;
                
                right: 11px;
            }
        }
        &:before {
            content: ' ';
            display: block;
            width: 20px;
            height: 2px;
            background: #BA564C;
            border-radius: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            text-align: center;
            color: #BA564C;
            transition-duration: 0.3s;
            @media (max-width: 768px) {
                width: 16px;
                right: 4px;
            }
        }
    }
    &__question-text {
        font-size: rem(18);
        line-height: 130%;
        font-weight: 500;
        padding-right: 30px;
        @media (max-width: 768px) {
            font-size: rem(14);
        }
    }
    &__answer {
        display: none;
        font-size: rem(16);
        line-height: 130%;
        margin-top: 15px;
        @media (max-width: 768px) {
            font-size: rem(12);
            margin-top: 12px;
        }
    }
}