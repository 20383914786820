.button {
    font-family: 'Rubik';
    font-size: rem(16);
    line-height: rem(20);
    color: $white-txt;
    padding: 20px;
    background: $main-btn;
    border-radius: 4px;
    width: 100%;
    max-width: 272px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
        background: $main-btn;
    }
    &:active {
        background: $main-btn;
    }
    @media (max-width: 768px) {
        font-size: rem(14);
        line-height: rem(17);
        padding: 17px 24px 16px;
        max-width: 100%;
    }
}
