.results {
    background: #FDF7F4;
    padding: 120px 0;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
    &__title {
        font-family: 'Cormorant';
        font-size: rem(65);
        line-height: 120%;
        text-transform: uppercase;
        width: 100%;
        max-width: 700px;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__content {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            margin-top: 40px;
            flex-direction: column;
        }
    }
    &__item {
        width: 32%;
        @media (max-width: 768px) {
            width: 100%;
            &:nth-child(n+2) {
                margin-top: 10px;
            }
        }
    }
    &__content--censored {
        display: none;
        margin: 50px -10px 0;
        .results__item {
            padding: 27px;
            border: 2px dashed #C19783;
            border-radius: 4px;
            min-height: 375px;
            background: $white-txt;
            font-size: rem(16);
            line-height: 135%;
            margin: 0 10px;
        }
    }
}
