.foryou {
    background: #F5DDD1 calc(50% - 412px) bottom url('../img/foryou-bg.png') no-repeat;
    padding: 120px 0;
    @media (max-width: 768px) {
        background-color: #F5DDD1;
        background-image: url('../img/foryou-mobile-bg-1.png'), url('../img/foryou-mobile-bg-2.png');
        background-position: left bottom, right 10px top 25px;
        background-repeat: no-repeat, no-repeat;
        padding: 60px 0;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__title {
        font-family: 'Cormorant';
        font-size: rem(65);
        line-height: 120%;
        text-transform: uppercase;
        width: 100%;
        max-width: 598px;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__list {
        width: 100%;
        max-width: 598px;
        @media (max-width: 1200px) {
          margin-left: 20px;
        }
        @media (max-width: 1000px) {
            margin: 40px 0 0;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        &:nth-child(2) {
            margin-top: 20px;
            @media (max-width: 768px) {
                margin-top: 10px;
            }
        }
    }
    &__item {
        font-size: rem(16);
        line-height: 130%;
        color: $main-txt;
        background: $white-txt;
        border: 2px dashed #C19783;
        padding: 72px 17px 19px;
        border-radius: 4px;
        width: 100%;
        max-width: 289px;
        position: relative;
        @media (max-width: 768px) {
            font-size: rem(14);
            padding: 62px 18px 18px;
            max-width: 100%;
        }
        &:before {
            content: ' ';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 18px;
            left: 17px;
            @media (max-width: 768px) {
                width: 32px;
                height: 32px;
            }
        }
        &--diet {
            &:before {
                background: url('../img/foryou-diet.png') no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                }
            }
        }
        &--tired {
            @media (max-width: 1200px) {
              margin-left: 20px;
            }
            @media (max-width: 768px) {
                margin: 10px 0 0;
            }
            &:before {
                background: url('../img/foryou-tired.png') no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                }
            }
        }
        &--cellulite {
            &:before {
                background: url('../img/foryou-cellulite.png') no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                }
            }
        }
        &--health {
            @media (max-width: 1200px) {
              margin-left: 20px;
            }
            @media (max-width: 768px) {
                margin: 10px 0 0;
            }
            &:before {
                background: url('../img/foryou-health.png') no-repeat;
                @media (max-width: 768px) {
                    background-size: contain;
                }
            }
        }
    }
}
