.form {
  margin-top: 20px;
  padding: 49px;
  background: #fff;
  border-radius: 4px;
  border: 2px dashed #C19783;
  @media (max-width: 768px) {
    padding: 38px 18px;
    margin: 0;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__column {
    width: 100%;
    &--left {
      max-width: 378px;
    }
    &--right {
      max-width: 650px;
      @media (max-width: 1200px) {
        margin-left: 20px;
      }
      @media (max-width: 768px) {
        margin: 30px 0 0;
      }
    }
  }
  &__mail {
    display: none;
    padding: 16px 21px 29px 16px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(165, 165, 165, 0.21);
    border-radius: 4px;
    margin-bottom: 20px;
  }
  &__error {
    display: none;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  &__title {
    font-size: rem(25);
    line-height: 120%;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: rem(16);
      margin-bottom: 10px;
    }
  }
  &__prices {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__price {
    &--new {
      font-size: rem(60);
      line-height: 120%;
      font-weight: 900;
      color: #F04332;
      @media (max-width: 768px) {
        font-size: rem(40);
      }
    }
    &--old {
      font-size: rem(30);
      line-height: 120%;
      color: #B2BAB3;
      text-decoration: line-through;
      margin-left: 30px;
      @media (max-width: 768px) {
        font-size: rem(20);
        margin-left: 10px;
      }
    }
  }
  &__timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 30px 26px;
    background: #F04332;
    border-radius: 4px;
    width: 100%;
    max-width: 378px;
    @media (max-width: 768px) {
      padding: 22px 16px;
    }
    &-note {
      font-size: rem(16);
      line-height: 130%;
      font-weight: 500;
      text-transform: uppercase;
      color: $white-txt;
      width: 100%;
      max-width: 182px;
      @media (max-width: 768px) {
        font-size: rem(14);
      }
    }
  }
  &__subtitle {
    font-size: rem(20);
    line-height: 130%;
    font-weight: 500;
    margin-bottom: 23px;
    @media (max-width: 768px) {
      font-size: rem(14);
      margin-bottom: 20px;
    }
  }
  &__input-container {
    position: relative;
  }
  &__input {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-size: rem(16);
    line-height: 130%;
    padding: 19px 20px 19px;
    width: 100%;
    &::placeholder {
      font-size: rem(16);
      line-height: 130%;
      color: #A9A9A9;
    }
    @media (max-width: 768px) {
      font-size: rem(14);
      padding: 15px;
      &::placeholder {
        font-size: rem(14);
      }
    }
  }
  & .button {
    max-width: none;
  }
}
.basket {
  &__content {
    /* max-width: 287px; */
    width: 100%;

  }
  &__button {
    font-size: rem(16);
    line-height: rem(20);
    letter-spacing: 1px;
    color: $white-txt;
    padding: 22px 20px 18px;
    background: $main-btn;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    margin-top: 28px;
    transition: all .2s ease-in-out;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.35; 
    }
    &:hover {
      background: $main-btn;
    }
    &:active {
      background: $main-btn;
    }
    @media (max-width: 768px) {
      font-size: rem(14);
      padding: 15px 24px;
      margin-top: 30px;
    }
  }
  @keyframes animatedButton {
    from {
      background-position: -3000px;
    }
    to {
      background-position: 0;
    }
  }
  &__button-loader:disabled {
    background-size: 3000%;
    background-image: repeating-linear-gradient(135deg, #BA564C, #BA564C 10px, #d69a94 10px, #d69a94 20px);
    animation: animatedButton 60s infinite linear;
  }
  &__check, &__check2 {
    position: relative;
    align-items: flex-start;
    margin-top: 12px;
    width: 100%;
    z-index: 10;
    padding-left: 28px;
    text-align: left;
    font-size: rem(12);
    line-height: 130%;
    display: none;
  }
  &__check-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__check-box {
    user-select: none;
  }
  &__check-input + label::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 1px;
    top: 2px;
    margin-right: 3px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  &__check-input + label::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    cursor: pointer;
    z-index: -2;
  }
  &__check-input:checked+label::before {
    background-image: url("../img/checkPay.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
  }
  &__check-text {
    font-weight: normal;
    font-size: rem(12);
    line-height: 135%;
    @media (max-width: 768px) {}
    &--data {
      color: #1D54E0;
      text-decoration: underline;
    }
    &--data-top {
      text-decoration: underline;
      color: #1D54E0;
      font-size: 12px;
    }
  }
}