.menu {
  	padding: 40px 0 0;
  	background: #FDF7F4;
  	&__content {
  		display: flex;
  		justify-content: space-between;
  	}
  	@media (max-width: 768px) {
  	    padding: 24px 0 0;
  	}
}