@charset "UTF-8";
/* Стандартный transition для hover-анимаций */
/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #242424; }

* {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin: 0;
  padding: 0; }

ul, li {
  padding: 0;
  margin: 0; }

.container {
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 8px; }
  @media (max-width: 768px) {
    .container {
      max-width: 480px;
      padding: 0 16px; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input; }

.fade-animation {
  -webkit-animation: fade-animation;
  animation: fade-animation 2s linear; }

@-webkit-keyframes fade-animation {
  from {
    opacity: 0.1; }
  to {
    opacity: 1; } }

@media (max-width: 768px) {
  .logo {
    width: 128px; } }

.login-btn {
  font-size: 0.75rem;
  line-height: 130%;
  color: #242424;
  padding: 12px 12px 11px;
  background: transparent;
  border: 1px solid #242424;
  border-radius: 4px;
  width: 100%;
  max-width: 87px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-in-out; }
  .login-btn:hover {
    color: #FFFFFF;
    background: #BA564C; }
  @media (max-width: 768px) {
    .login-btn {
      font-size: 0.625rem;
      max-width: 79px;
      padding: 7px; } }

.slick-dots {
  bottom: -30px; }

.slide-arrow {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #F5DDD1;
  border: none;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; }
  .slide-arrow:before, .slide-arrow:after {
    content: ' ';
    display: block;
    width: 11px;
    height: 2px;
    background: #C19783;
    border-radius: 8px;
    position: absolute; }

.prev-arrow {
  left: -70px; }
  .prev-arrow:before {
    transform: rotate(45deg);
    top: 32px;
    left: 24px; }
  .prev-arrow:after {
    transform: rotate(-45deg);
    top: 25px;
    left: 24px; }

.next-arrow {
  right: -70px; }
  .next-arrow:before {
    transform: rotate(45deg);
    top: 25px;
    left: 25px; }
  .next-arrow:after {
    transform: rotate(-45deg);
    top: 32px;
    left: 25px; }

.slick-dots li {
  width: 10px;
  height: 10px; }
  .slick-dots li.slick-active button {
    opacity: 1; }
  .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background: #C19783;
    opacity: 0.3; }
    .slick-dots li button:before {
      display: none; }

.censor {
  display: none; }

.main {
  background: #FDF7F4 calc(50% + 365px) 10px url("../img/main-bg.png") no-repeat;
  padding: 120px 0 214px;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .main {
      padding: 40px 0 280px;
      background-size: 228px;
      background-position: center bottom; } }
  .main__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 700px;
    text-transform: uppercase; }
    @media (max-width: 1000px) {
      .main__title {
        font-size: 3.75rem; } }
    @media (max-width: 768px) {
      .main__title {
        font-size: 1.875rem; } }
  .main__subtitle {
    font-size: 1.25rem;
    line-height: 130%;
    font-weight: 500;
    width: 100%;
    max-width: 700px;
    margin-top: 30px; }
    .main__subtitle span {
      color: #BA564C; }
    @media (max-width: 768px) {
      .main__subtitle {
        font-size: 0.875rem;
        margin-top: 10px; } }
  .main .button {
    margin-top: 50px; }
    @media (max-width: 768px) {
      .main .button {
        margin-top: 20px; } }
  .main .ad {
    margin-top: 30px; }
    @media (max-width: 768px) {
      .main .ad {
        margin-top: 20px; } }

.footer {
  color: #595959;
  background-color: transparent;
  padding: 40px 0 20px; }
  .footer__inner {
    display: flex;
    justify-content: space-around;
    align-items: end;
    flex-wrap: wrap;
    gap: 15px; }
  .footer__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: fit-content; }
  .footer__info {
    margin-bottom: 8px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 113%; }
    .footer__info--link {
      text-decoration: none;
      color: #595959; }
      .footer__info--link:last-child {
        margin-bottom: 0; }
    .footer__info--legal {
      margin-bottom: -4px;
      line-height: 170%; }
    .footer__info--title {
      margin: 0 0 3px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center; }
  .footer__pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 280px;
    margin: 0 0 16px;
    gap: 10px; }
    .footer__pay--cards {
      align-items: baseline;
      max-width: 185px;
      margin: 0 auto; }

@media (max-width: 600px) {
  .footer__inner {
    flex-direction: column;
    align-items: center; }
  .footer__item {
    max-width: 270px; }
    .footer__item .footer__info--title {
      text-align: left; }
    .footer__item:nth-child(2) {
      order: 3; }
      .footer__item:nth-child(2) .footer__pay--cards {
        margin-left: 0; } }

.form {
  margin-top: 20px;
  padding: 49px;
  background: #fff;
  border-radius: 4px;
  border: 2px dashed #C19783; }
  @media (max-width: 768px) {
    .form {
      padding: 38px 18px;
      margin: 0; } }
  .form__inner {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .form__inner {
        flex-direction: column; } }
  .form__column {
    width: 100%; }
    .form__column--left {
      max-width: 378px; }
    .form__column--right {
      max-width: 650px; }
      @media (max-width: 1200px) {
        .form__column--right {
          margin-left: 20px; } }
      @media (max-width: 768px) {
        .form__column--right {
          margin: 30px 0 0; } }
  .form__mail {
    display: none;
    padding: 16px 21px 29px 16px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(165, 165, 165, 0.21);
    border-radius: 4px;
    margin-bottom: 20px; }
  .form__error {
    display: none;
    font-size: 14px;
    text-align: center;
    margin-top: 10px; }
  .form__title {
    font-size: 1.5625rem;
    line-height: 120%;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .form__title {
        font-size: 1rem;
        margin-bottom: 10px; } }
  .form__prices {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px; }
    @media (max-width: 768px) {
      .form__prices {
        margin-bottom: 20px; } }
  .form__price--new {
    font-size: 3.75rem;
    line-height: 120%;
    font-weight: 900;
    color: #F04332; }
    @media (max-width: 768px) {
      .form__price--new {
        font-size: 2.5rem; } }
  .form__price--old {
    font-size: 1.875rem;
    line-height: 120%;
    color: #B2BAB3;
    text-decoration: line-through;
    margin-left: 30px; }
    @media (max-width: 768px) {
      .form__price--old {
        font-size: 1.25rem;
        margin-left: 10px; } }
  .form__timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 30px 26px;
    background: #F04332;
    border-radius: 4px;
    width: 100%;
    max-width: 378px; }
    @media (max-width: 768px) {
      .form__timer {
        padding: 22px 16px; } }
    .form__timer-note {
      font-size: 1rem;
      line-height: 130%;
      font-weight: 500;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 100%;
      max-width: 182px; }
      @media (max-width: 768px) {
        .form__timer-note {
          font-size: 0.875rem; } }
  .form__subtitle {
    font-size: 1.25rem;
    line-height: 130%;
    font-weight: 500;
    margin-bottom: 23px; }
    @media (max-width: 768px) {
      .form__subtitle {
        font-size: 0.875rem;
        margin-bottom: 20px; } }
  .form__input-container {
    position: relative; }
  .form__input {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 130%;
    padding: 19px 20px 19px;
    width: 100%; }
    .form__input::placeholder {
      font-size: 1rem;
      line-height: 130%;
      color: #A9A9A9; }
    @media (max-width: 768px) {
      .form__input {
        font-size: 0.875rem;
        padding: 15px; }
        .form__input::placeholder {
          font-size: 0.875rem; } }
  .form .button {
    max-width: none; }

.basket__content {
  /* max-width: 287px; */
  width: 100%; }

.basket__button {
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 1px;
  color: #FFFFFF;
  padding: 22px 20px 18px;
  background: #BA564C;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin-top: 28px;
  transition: all .2s ease-in-out; }
  .basket__button:disabled {
    cursor: not-allowed;
    opacity: 0.35; }
  .basket__button:hover {
    background: #BA564C; }
  .basket__button:active {
    background: #BA564C; }
  @media (max-width: 768px) {
    .basket__button {
      font-size: 0.875rem;
      padding: 15px 24px;
      margin-top: 30px; } }

@keyframes animatedButton {
  from {
    background-position: -3000px; }
  to {
    background-position: 0; } }

.basket__button-loader:disabled {
  background-size: 3000%;
  background-image: repeating-linear-gradient(135deg, #BA564C, #BA564C 10px, #d69a94 10px, #d69a94 20px);
  animation: animatedButton 60s infinite linear; }

.basket__check, .basket__check2 {
  position: relative;
  align-items: flex-start;
  margin-top: 12px;
  width: 100%;
  z-index: 10;
  padding-left: 28px;
  text-align: left;
  font-size: 0.75rem;
  line-height: 130%;
  display: none; }

.basket__check-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.basket__check-box {
  user-select: none; }

.basket__check-input + label::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 1px;
  top: 2px;
  margin-right: 3px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer; }

.basket__check-input + label::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 3px;
  width: 16px;
  height: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  cursor: pointer;
  z-index: -2; }

.basket__check-input:checked + label::before {
  background-image: url("../img/checkPay.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px; }

.basket__check-text {
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 135%; }
  .basket__check-text--data {
    color: #1D54E0;
    text-decoration: underline; }
  .basket__check-text--data-top {
    text-decoration: underline;
    color: #1D54E0;
    font-size: 12px; }

.button {
  font-family: 'Rubik';
  font-size: 1rem;
  line-height: 1.25rem;
  color: #FFFFFF;
  padding: 20px;
  background: #BA564C;
  border-radius: 4px;
  width: 100%;
  max-width: 272px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all .2s ease-in-out; }
  .button:hover {
    background: #BA564C; }
  .button:active {
    background: #BA564C; }
  @media (max-width: 768px) {
    .button {
      font-size: 0.875rem;
      line-height: 1.0625rem;
      padding: 17px 24px 16px;
      max-width: 100%; } }

.menu {
  padding: 40px 0 0;
  background: #FDF7F4; }
  .menu__content {
    display: flex;
    justify-content: space-between; }
  @media (max-width: 768px) {
    .menu {
      padding: 24px 0 0; } }

.ad {
  font-size: 1rem;
  line-height: 130%;
  color: #242424;
  padding: 20px 30px 18px 80px;
  background: #F5DDD1;
  border: 2px dashed #C19783;
  border-radius: 4px;
  width: 100%;
  max-width: 495px;
  position: relative; }
  .ad:before {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 23px;
    left: 28px;
    background: url("../img/ad-pic.png"); }
  .ad--white {
    background: #FFFFFF; }
  .ad--mobile {
    display: none; }
  @media (max-width: 768px) {
    .ad {
      font-size: 0.75rem;
      padding: 15px 15px 16px 50px; }
      .ad:before {
        width: 24px;
        height: 24px;
        background-size: contain;
        top: 26px;
        left: 15px; } }

.foryou {
  background: #F5DDD1 calc(50% - 412px) bottom url("../img/foryou-bg.png") no-repeat;
  padding: 120px 0; }
  @media (max-width: 768px) {
    .foryou {
      background-color: #F5DDD1;
      background-image: url("../img/foryou-mobile-bg-1.png"), url("../img/foryou-mobile-bg-2.png");
      background-position: left bottom, right 10px top 25px;
      background-repeat: no-repeat, no-repeat;
      padding: 60px 0; } }
  .foryou__content {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1000px) {
      .foryou__content {
        flex-direction: column;
        align-items: center; } }
  .foryou__title {
    font-family: 'Cormorant';
    font-size: 4.0625rem;
    line-height: 120%;
    text-transform: uppercase;
    width: 100%;
    max-width: 598px; }
    @media (max-width: 768px) {
      .foryou__title {
        font-size: 1.875rem; } }
  .foryou__list {
    width: 100%;
    max-width: 598px; }
    @media (max-width: 1200px) {
      .foryou__list {
        margin-left: 20px; } }
    @media (max-width: 1000px) {
      .foryou__list {
        margin: 40px 0 0; } }
  .foryou__row {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .foryou__row {
        flex-direction: column; } }
    .foryou__row:nth-child(2) {
      margin-top: 20px; }
      @media (max-width: 768px) {
        .foryou__row:nth-child(2) {
          margin-top: 10px; } }
  .foryou__item {
    font-size: 1rem;
    line-height: 130%;
    color: #242424;
    background: #FFFFFF;
    border: 2px dashed #C19783;
    padding: 72px 17px 19px;
    border-radius: 4px;
    width: 100%;
    max-width: 289px;
    position: relative; }
    @media (max-width: 768px) {
      .foryou__item {
        font-size: 0.875rem;
        padding: 62px 18px 18px;
        max-width: 100%; } }
    .foryou__item:before {
      content: ' ';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 18px;
      left: 17px; }
      @media (max-width: 768px) {
        .foryou__item:before {
          width: 32px;
          height: 32px; } }
    .foryou__item--diet:before {
      background: url("../img/foryou-diet.png") no-repeat; }
      @media (max-width: 768px) {
        .foryou__item--diet:before {
          background-size: contain; } }
    @media (max-width: 1200px) {
      .foryou__item--tired {
        margin-left: 20px; } }
    @media (max-width: 768px) {
      .foryou__item--tired {
        margin: 10px 0 0; } }
    .foryou__item--tired:before {
      background: url("../img/foryou-tired.png") no-repeat; }
      @media (max-width: 768px) {
        .foryou__item--tired:before {
          background-size: contain; } }
    .foryou__item--cellulite:before {
      background: url("../img/foryou-cellulite.png") no-repeat; }
      @media (max-width: 768px) {
        .foryou__item--cellulite:before {
          background-size: contain; } }
    @media (max-width: 1200px) {
      .foryou__item--health {
        margin-left: 20px; } }
    @media (max-width: 768px) {
      .foryou__item--health {
        margin: 10px 0 0; } }
    .foryou__item--health:before {
      background: url("../img/foryou-health.png") no-repeat; }
      @media (max-width: 768px) {
        .foryou__item--health:before {
          background-size: contain; } }

.marathon {
  background: #FDF7F4 calc(50% - 335px) 96px url("../img/marathon-bg.png") no-repeat;
  padding: 120px 0 170px;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .marathon {
      padding: 294px 0 60px;
      background-size: 229px;
      background-position: center top 40px; } }
  .marathon .container {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 768px) {
      .marathon .container {
        flex-direction: column; } }
  .marathon__content {
    width: 100%;
    max-width: 598px; }
    @media (max-width: 1200px) {
      .marathon__content {
        max-width: 50%; } }
    @media (max-width: 768px) {
      .marathon__content {
        max-width: 100%; } }
  .marathon__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 700px;
    text-transform: uppercase; }
    @media (max-width: 1000px) {
      .marathon__title {
        font-size: 3.125rem; } }
    @media (max-width: 768px) {
      .marathon__title {
        font-size: 1.875rem; } }
  .marathon__list {
    margin-top: 50px; }
    @media (max-width: 768px) {
      .marathon__list {
        margin-top: 30px; } }
  .marathon__item {
    font-size: 1.25rem;
    line-height: 130%;
    color: #242424;
    padding-left: 32px;
    position: relative; }
    @media (max-width: 768px) {
      .marathon__item {
        font-size: 0.875rem;
        padding-left: 22px; } }
    .marathon__item:before {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      background: url("../img/marathon-list.png");
      position: absolute;
      top: 4px;
      left: 0; }
      @media (max-width: 768px) {
        .marathon__item:before {
          width: 14px;
          height: 14px;
          background-size: contain; } }
    .marathon__item:nth-child(n+2) {
      margin-top: 15px; }
      @media (max-width: 768px) {
        .marathon__item:nth-child(n+2) {
          margin-top: 10px; } }

.habits {
  background: #F5DDD1 calc(50% + 411px) bottom url("../img/habits-bg.png") no-repeat;
  padding: 120px 0;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .habits {
      background-color: #F5DDD1;
      background-image: url("../img/foryou-mobile-bg-1.png"), url("../img/foryou-mobile-bg-2.png");
      background-position: right -110px bottom -20px, right 10px top 25px;
      background-repeat: no-repeat, no-repeat;
      padding: 60px 0; } }
  .habits .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1000px) {
      .habits .container {
        flex-direction: column; } }
  .habits__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 598px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .habits__title {
        font-size: 1.875rem; } }
  .habits__content {
    width: 100%;
    max-width: 495px; }
    @media (max-width: 1000px) {
      .habits__content {
        margin-top: 40px; } }
    @media (max-width: 768px) {
      .habits__content {
        margin-top: 20px; } }
  .habits__subtitle {
    font-size: 1.25rem;
    line-height: 130%; }
    @media (max-width: 768px) {
      .habits__subtitle {
        font-size: 0.875rem; } }
  .habits .button {
    margin-top: 20px; }
    @media (max-width: 768px) {
      .habits .button {
        margin-top: 30px; } }

.after {
  background: #FDF7F4 calc(50% + 310px) top url("../img/after-bg.png") no-repeat;
  padding: 120px 0;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .after {
      padding: 60px 0 364px;
      background-size: 228px;
      background-position: center bottom 62px; } }
  .after .container {
    display: flex;
    justify-content: flex-start; }
    @media (max-width: 768px) {
      .after .container {
        flex-direction: column; } }
  .after__content {
    width: 100%;
    max-width: 598px; }
    @media (max-width: 1000px) {
      .after__content {
        max-width: 60%; } }
    @media (max-width: 768px) {
      .after__content {
        max-width: 100%; } }
  .after__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 700px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .after__title {
        font-size: 1.875rem; } }
  .after__list {
    margin-top: 50px; }
    @media (max-width: 768px) {
      .after__list {
        margin-top: 40px; } }
  .after__item {
    font-size: 1.25rem;
    line-height: 130%;
    color: #242424;
    padding-left: 32px;
    position: relative; }
    @media (max-width: 768px) {
      .after__item {
        font-size: 0.875rem;
        padding-left: 22px; } }
    .after__item:before {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      background: url("../img/marathon-list.png");
      position: absolute;
      top: 4px;
      left: 0; }
      @media (max-width: 768px) {
        .after__item:before {
          width: 14px;
          height: 14px;
          background-size: contain; } }
    .after__item:nth-child(n+2) {
      margin-top: 15px; }
      @media (max-width: 768px) {
        .after__item:nth-child(n+2) {
          margin-top: 10px; } }

.order {
  background: #F5DDD1 calc(50% + 470px) top url("../img/order-bg.png") no-repeat;
  padding: 120px 0;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .order {
      background: #F5DDD1 right top url("../img/order-mobile-bg.png") no-repeat;
      padding: 60px 0; } }
  .order__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media (max-width: 768px) {
      .order__content {
        flex-direction: column; } }
  .order__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 700px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .order__title {
        font-size: 1.875rem; } }
  .order__subtitle {
    font-size: 1.25rem;
    line-height: 130%;
    font-weight: 500;
    width: 100%;
    max-width: 566px;
    margin-top: 20px;
    padding-bottom: 30px; }
    @media (max-width: 768px) {
      .order__subtitle {
        font-size: 0.875rem;
        margin-top: 10px; } }
  @media (max-width: 768px) {
    .order .ad--white {
      display: none; } }
  @media (max-width: 768px) {
    .order .ad--mobile {
      display: block;
      margin-top: 10px; } }

.timer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 102px; }
  .timer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 45px; }
  .timer__num {
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.875rem;
    text-align: center;
    color: #FFFFFF; }
    .timer__num--dots {
      position: relative; }
      .timer__num--dots:after {
        content: ":";
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.875rem;
        line-height: 1.875rem; }
  .timer__text {
    font-size: 0.75rem;
    line-height: 130%;
    text-align: center;
    text-transform: none;
    color: #FFFFFF; }

.results {
  background: #FDF7F4;
  padding: 120px 0; }
  @media (max-width: 768px) {
    .results {
      padding: 60px 0; } }
  .results__title {
    font-family: 'Cormorant';
    font-size: 4.0625rem;
    line-height: 120%;
    text-transform: uppercase;
    width: 100%;
    max-width: 700px; }
    @media (max-width: 768px) {
      .results__title {
        font-size: 1.875rem; } }
  .results__content {
    margin-top: 50px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .results__content {
        margin-top: 40px;
        flex-direction: column; } }
  .results__item {
    width: 32%; }
    @media (max-width: 768px) {
      .results__item {
        width: 100%; }
        .results__item:nth-child(n+2) {
          margin-top: 10px; } }
  .results__content--censored {
    display: none;
    margin: 50px -10px 0; }
    .results__content--censored .results__item {
      padding: 27px;
      border: 2px dashed #C19783;
      border-radius: 4px;
      min-height: 375px;
      background: #FFFFFF;
      font-size: 1rem;
      line-height: 135%;
      margin: 0 10px; }

.faq {
  background: #F5DDD1 calc(50% + 470px) top url("../img/order-bg.png") no-repeat;
  padding: 120px 0;
  color: #242424;
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .faq {
      background: #F5DDD1 right top url("../img/order-mobile-bg.png") no-repeat;
      padding: 60px 0; } }
  .faq__title {
    font-size: 4.0625rem;
    line-height: 120%;
    font-family: 'Cormorant';
    color: #242424;
    width: 100%;
    max-width: 700px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .faq__title {
        font-size: 1.875rem; } }
  .faq__list {
    width: 100%;
    margin: 50px 0 0; }
    @media (max-width: 768px) {
      .faq__list {
        margin-top: 40px; } }
  .faq__item {
    background: #FFFFFF;
    border: 1px dashed #C19783;
    border-radius: 4px;
    padding: 29px 40px;
    margin-bottom: 10px;
    cursor: pointer; }
    @media (max-width: 768px) {
      .faq__item {
        padding: 15px; } }
    .faq__item:last-child {
      margin-bottom: 0; }
    .faq__item.active .faq__question:before, .faq__item.active .faq__question:after {
      transform: translateY(-50%) rotate(45deg); }
  .faq__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    .faq__question:after {
      content: ' ';
      display: block;
      width: 2px;
      height: 20px;
      background: #BA564C;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      text-align: center;
      color: #BA564C;
      transition-duration: 0.3s; }
      @media (max-width: 768px) {
        .faq__question:after {
          height: 16px;
          right: 11px; } }
    .faq__question:before {
      content: ' ';
      display: block;
      width: 20px;
      height: 2px;
      background: #BA564C;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      text-align: center;
      color: #BA564C;
      transition-duration: 0.3s; }
      @media (max-width: 768px) {
        .faq__question:before {
          width: 16px;
          right: 4px; } }
  .faq__question-text {
    font-size: 1.125rem;
    line-height: 130%;
    font-weight: 500;
    padding-right: 30px; }
    @media (max-width: 768px) {
      .faq__question-text {
        font-size: 0.875rem; } }
  .faq__answer {
    display: none;
    font-size: 1rem;
    line-height: 130%;
    margin-top: 15px; }
    @media (max-width: 768px) {
      .faq__answer {
        font-size: 0.75rem;
        margin-top: 12px; } }
