.after {
    background: #FDF7F4 calc(50% + 310px) top url('../img/after-bg.png') no-repeat;
    padding: 120px 0;
    color: $main-txt;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        padding: 60px 0 364px;
        background-size: 228px;
        background-position: center bottom 62px;
    }
    & .container {
        display: flex;
        justify-content: flex-start;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__content {
        width: 100%;
        max-width: 598px;
        @media (max-width: 1000px) {
            max-width: 60%;
        }
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    &__title {
        font-size: rem(65);
        line-height: 120%;
        font-family: 'Cormorant';
        color: $main-txt;
        width: 100%;
        max-width: 700px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__list {
        margin-top: 50px;
        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }
    &__item {
        font-size: rem(20);
        line-height: 130%;
        color: $main-txt;
        padding-left: 32px;
        position: relative;
        @media (max-width: 768px) {
            font-size: rem(14);
            padding-left: 22px;
        }
        &:before {
            content: ' ';
            display: block;
            width: 20px;
            height: 20px;
            background: url('../img/marathon-list.png');
            position: absolute;
            top: 4px;
            left: 0;
            @media (max-width: 768px) {
                width: 14px;
                height: 14px;
                background-size: contain;
            }
        }
        &:nth-child(n+2) {
            margin-top: 15px;
            @media (max-width: 768px) {
                margin-top: 10px;
            }
        }
    }
}
