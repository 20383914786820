.order {
    background: #F5DDD1 calc(50% + 470px) top url('../img/order-bg.png') no-repeat;
    padding: 120px 0;
    color: $main-txt;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        background: #F5DDD1 right top url('../img/order-mobile-bg.png') no-repeat;
        padding: 60px 0;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    @media (max-width: 768px) {}
    &__title {
        font-size: rem(65);
        line-height: 120%;
        font-family: 'Cormorant';
        color: $main-txt;
        width: 100%;
        max-width: 700px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__subtitle {
        font-size: rem(20);
        line-height: 130%;
        font-weight: 500;
        width: 100%;
        max-width: 566px;
        margin-top: 20px;
        padding-bottom: 30px;
        @media (max-width: 768px) {
            font-size: rem(14);
            margin-top: 10px;
        }
    }
    & .ad--white {
        @media (max-width: 768px) {
            display: none;
        }
    }
    & .ad--mobile {
        @media (max-width: 768px) {
            display: block;
            margin-top: 10px;
        }
    }
}
