.ad {
  	font-size: rem(16);
  	line-height: 130%;
  	color: $main-txt;
  	padding: 20px 30px 18px 80px;
  	background: #F5DDD1;
  	border: 2px dashed #C19783;
	border-radius: 4px;
	width: 100%;
	max-width: 495px;
	position: relative;
	&:before {
		content: ' ';
		display: block;
		width: 32px;
		height: 32px;
		position: absolute;
		top: 23px;
		left: 28px;
		background: url('../img/ad-pic.png');
	}
	&--white {
		background: #FFFFFF;
	}
	&--mobile {
		display: none;
	}
	@media (max-width: 768px) {
	    font-size: rem(12);
	    padding: 15px 15px 16px 50px;
	    &:before {
	    	width: 24px;
	    	height: 24px;
	    	background-size: contain;
	    	top: 26px;
	    	left: 15px;
	    }
	}
}
