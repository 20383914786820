.timer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 102px;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 45px;
    }
    &__num {
      font-weight: 700;
      font-size: rem(30);
      line-height: rem(30);
      text-align: center;
      color: $white-txt;
      &--dots {
        position: relative;
        &:after {
          content: ":";
          position: absolute;
          right: -14px;
          top: 50%;
          transform: translateY(-50%);
          font-size: rem(30);
          line-height: rem(30);
        }
      }
    }
    &__text {
      font-size: rem(12);
      line-height: 130%;
      text-align: center;
      text-transform: none;
      color: $white-txt;
    }
  }
  @media (max-width: 740px) {}