.habits {
    background: #F5DDD1 calc(50% + 411px) bottom url('../img/habits-bg.png') no-repeat;
    padding: 120px 0;
    color: $main-txt;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        background-color: #F5DDD1;
        background-image: url('../img/foryou-mobile-bg-1.png'), url('../img/foryou-mobile-bg-2.png');
        background-position: right -110px bottom -20px, right 10px top 25px;
        background-repeat: no-repeat, no-repeat;
        padding: 60px 0;
    }
    & .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }
    &__title {
        font-size: rem(65);
        line-height: 120%;
        font-family: 'Cormorant';
        color: $main-txt;
        width: 100%;
        max-width: 598px;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: rem(30);
        }
    }
    &__content {
        width: 100%;
        max-width: 495px;
        @media (max-width: 1000px) {
            margin-top: 40px;
        }
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
    &__subtitle {
        font-size: rem(20);
        line-height: 130%;
        @media (max-width: 768px) {
            font-size: rem(14);
        }
    }
    & .button {
        margin-top: 20px;
        @media (max-width: 768px) {
            margin-top: 30px;
        }
    }
}
